<template>
    <section class="px-3 ml-3">
        <navbar-admin icon="icon-archive" />
        <titulo-divisor titulo="Dashboard">
            <div class="row d-middle-center">
                <div class="col-auto px-1">
                    <el-tooltip content="Dashboard" placement="bottom" effect="light" visible-arrow>
                        <button class="btn btn-square32-5d cr-pointer">
                            <i class="icon-finance f-19 text-white" />
                        </button>
                    </el-tooltip>
                </div>
                <div class="col-auto pl-1">
                    <filter-indicator :count="contar" @openModalFilter="abrirModalFiltros" @clearFilter="clearsFilters"/>
                </div>
            </div>
        </titulo-divisor>
        <br>
        <div class="w-80 my-5 mx-auto">
            <div class="card-grafica py-4">
                <p class="text-center text-general f-16 py-4 f-600">Cantidad materiales por bodega</p>
                <echart :options="graficaMaterialesBodega" />
            </div>
            <div class="card-grafica py-4 mt-5">
                <p class="text-center text-general f-16 py-4 f-600">Valor por bodega</p>
                <echart :options="graficaValorBodega" />
            </div>

            <div class="row mx-auto mt-5">
                <div class="col-6">
                    <p class="text-center text-general f-16 py-4 f-600">Cantidad entrada de materiales</p>
                    <div class="w-50 mx-auto">
                        <p class="f-12 text-muted ">Elija la bodega</p>
                        <el-select v-model="bodega_entrada" clearable placeholder="Bodega" @change="getEntradas"  size="small" class="w-100">
                            <el-option v-for="item in select_bodegas.bodegas" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                    </div>
                    <div class="card-grafica mt-4 py-5">
                        <echart :options="graficaEntrada" />
                        <echart :options="graficaEntradaBarra" />
                    </div>
                </div>
                <div class="col-6">
                    <p class="text-center text-general f-16 py-4 f-600">Cantidad salida de materiales</p>
                    <div class="w-50 mx-auto">
                        <p class="f-12 text-muted ">Elija la bodega</p>
                        <el-select v-model="bodega_salida" clearable placeholder="Bodega" @change="getSalidas"  size="small" class="w-100">
                            <el-option v-for="item in select_bodegas.bodegas" :key="item.id" :label="item.nombre" :value="item.id" />
                        </el-select>
                    </div>
                    <div class="card-grafica mt-4 py-5">
                        <echart :options="graficaSalida" />
                        <echart :options="graficaSalidaBarra" />
                    </div>
                </div>
            </div>
        </div>

        <modal-lateral ref="abrirModalFiltros" titulo="Filtro">
            <div class="row justify-content-center mx-0">
                <div class="col-11 mt-4">
                    <p class="input-label-top">Rango fecha incial</p>
                    <el-date-picker
                        v-model="filters.fecha_min"
                        type="date"
                        class="w-100"
                        placeholder="Fecha incial">
                    </el-date-picker>
                </div>
                <div class="col-11 mt-4">
                    <p class="input-label-top">Rango fecha final</p>
                    <el-date-picker
                        v-model="filters.fecha_max"
                        type="date"
                        class="w-100"
                        placeholder="Fecha final">
                    </el-date-picker>
                </div>
                <div class="col-11 mt-4">
                    <p class="input-label-top">Pais</p>
                    <el-select v-model="filters.id_pais" clearable placeholder="Pais" @change="getState" filterable size="small" class="w-100">
                        <el-option v-for="item in select_countries" :key="item.id" :label="item.pais" :value="item.id" />
                    </el-select>
                </div>
                <div class="col-11 mt-4">
                    <p class="input-label-top">Sector/Departamento/Estado</p>
                    <el-select v-model="filters.id_estado" clearable placeholder="Sector/Departamento/Estado" filterable size="small" class="w-100">
                        <el-option v-for="item in selects_states" :key="item.id" :label="item.estado" :value="item.id" />
                    </el-select>
                </div>
            </div>
            <div class="botones">
                <button type="button" class="btn mx-2 btn-limpiar f-12 h-32px" @click="handleFilter"> Limpiar </button>
                <button type="button" class="btn mx-2 btn-general w-80px f-12 h-32px text-white" @click="listaFiltros"> Filtrar </button>
            </div>
        </modal-lateral>
    </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Dashboard',
    data() {
        return {
            graficaMaterialesBodega: {
                textStyle: {
                    color: '#0B56A7'
                },
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    textStyle: {
                        color: '#fff',
                        fontSize: 12
                    },
                    formatter: '{b} <br/> {c} Materiales'
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {
                        interval: '0',
                        width: '80',
                        showMinLabel: 'true',
                        overflow: 'break'
                    },
                },
                yAxis: {
                    name: 'Cantidad material',
                    type: 'value'
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        colorBy: {
                        },
                        barWidth: 26,

                    }
                ]
            },
            graficaValorBodega: {
                textStyle: {
                    color: '#0B56A7'
                },
                tooltip: {
                    trigger: 'item',
                    backgroundColor: 'rgba(0,0,0,0.8)',
                    textStyle: {
                        color: '#fff',
                        fontSize: 12
                    },
                    formatter: ({name, value}) => {
                        return `${name} <br/> ${this.formatoMoneda(value)}`
                    }
                },
                xAxis: {
                    type: 'category',
                    data: [],
                    axisLabel: {
                        interval: '0',
                        width: '80',
                        showMinLabel: 'true',
                        overflow: 'break'
                    },
                },
                yAxis: {
                    name: 'Valor bodega',
                    type: 'value',
                    axisLabel: {
                        formatter: (value) => {
                            return this.formatoMoneda(value)
                        },
                    }
                },
                series: [
                    {
                        data: [],
                        type: 'bar',
                        showBackground: true,
                        backgroundStyle: {
                            color: 'rgba(180, 180, 180, 0.2)'
                        },
                        colorBy: {
                        },
                        barWidth: 26,

                    }
                ]
            },
            graficaEntrada: {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    bottom: 'bottom',
                    height: 'auto',
                    type: 'scroll'
                },

                series: [{
                    name: 'Entrada materiales',
                    type: 'pie',
                    radius: [40, 90],
                    center: ['50%', '50%'],
                    itemStyle: {
                        borderRadius: 8
                    },
                    data: [],
                }]
            },
            graficaEntradaBarra: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: []
                },
                series: [
                    {
                        name: 'Entrada materiales:',
                        type: 'bar',
                        stack: 'chart',
                        label: {
                            position: 'right',
                            show: true
                        },
                        data: [],
                        colorBy: {
                        },
                    },

                ]
            },
            graficaSalida: {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    bottom: 'bottom',
                    height: 'auto',
                    type: 'scroll'
                },

                series: [{
                    name: 'Salida materiales',
                    type: 'pie',
                    radius: [40, 90],
                    center: ['50%', '50%'],
                    itemStyle: {
                        borderRadius: 8
                    },
                    data: [],
                }]
            },
            graficaSalidaBarra: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    boundaryGap: [0, 0.01]
                },
                yAxis: {
                    type: 'category',
                    data: []
                },
                series: [
                    {
                        name: 'Salida materiales:',
                        type: 'bar',
                        stack: 'chart',
                        label: {
                            position: 'right',
                            show: true
                        },
                        data: [],
                        colorBy: {
                        },
                    },

                ]
            },
            bodega_entrada: '',
            bodega_salida: '',
        }
    },
    computed: {
        ...mapGetters({
            material: 'bodega/dashboard/material',
            valor: 'bodega/dashboard/valor',
            entrada: 'bodega/dashboard/entrada',
            salida: 'bodega/dashboard/salida',
            filters: 'bodega/dashboard/filters',
            select_bodegas: 'selects/selects/select_bodegas',
            select_countries: 'selects/selects/select_countries',
            selects_states: 'selects/selects/select_states',
        }),
        filter: {
            get() {
                return this.$store.getters['bodega/dashboard/filters']
            },
            set(val) {
                this.$store.commit('compras/dashboard/set_filters', val)
            }
        },
        pais: {
            get() { return this.filters.pais },
            set(val) { this.filters.pais = val ? val[0] : '' }
        },
        contar() {
            return this.filters.count();
        }
    },
    async created() {
        this.$store.dispatch('navigation/breadcumbs/getBreadcumbs', [
            'bodega.main',
            'bodega.dashboard'
        ]);
        await Promise.all([
            this.Action_get_selects_bodegas(),
            this.Action_get_select_countries(),
            this.Action_get_materiales(),
            this.Action_get_valores(),
        ])
        this.clearsFilters();

        this.llenadoGraficaMateriales();
        this.llenadoGraficaValores();
    },
    methods: {
        ...mapActions({
            Action_get_materiales: 'bodega/dashboard/Action_get_dashboard_bodegas_material',
            Action_get_valores: 'bodega/dashboard/Action_get_dashboard_bodegas_valor',
            Action_get_entradas: 'bodega/dashboard/Action_get_dashboard_bodegas_entrada',
            Action_get_salidas: 'bodega/dashboard/Action_get_dashboard_bodegas_salida',
            Action_get_selects_bodegas: 'selects/selects/Action_get_selects_bodegas',
            Action_get_select_countries: 'selects/selects/Action_get_select_countries',
            Action_get_selects_states: 'selects/selects/Action_get_select_states',
            clearsFilters: 'bodega/dashboard/Action_clear_filters',
        }),
        async listaFiltros() {
            await this.Action_get_materiales()
            await this.Action_get_valores()
            this.llenadoGraficaMateriales()
            this.llenadoGraficaValores()
            this.abrirModalFiltros()
        },
        abrirModalFiltros() {
            this.$refs.abrirModalFiltros.toggle()
        },
        llenadoGraficaMateriales() {
            let nombres = [];
            let materiales = [];
            this.material.forEach(e => {
                nombres.push(e.bodega);
                materiales.push(e.cantidad_material);
            });
            this.graficaMaterialesBodega.xAxis.data = nombres;
            this.graficaMaterialesBodega.series[0].data = materiales;
        },
        llenadoGraficaValores() {
            let nombres = [];
            let valores = [];
            this.valor.forEach(e => {
                nombres.push(e.bodega);
                valores.push(e.valor);
            });
            this.graficaValorBodega.xAxis.data = nombres;
            this.graficaValorBodega.series[0].data = valores;
        },
        llenadoGraficaEntrada() {
            let nombres = [];
            let entradas = [];
            let grafica = [];
            this.entrada.forEach(e => {
                nombres.push(`${e.mes_abre}-${e.anio}`);
                entradas.push(e.cantidad);
                grafica.push({
                    value: e.cantidad,
                    name: `${e.mes}-${e.anio}`
                })
            });
            this.graficaEntradaBarra.yAxis.data = nombres;
            this.graficaEntradaBarra.series[0].data = entradas;
            this.graficaEntrada.series[0].data = grafica;
        },
        llenadoGraficaSalida() {
            let nombres = [];
            let entradas = [];
            let grafica = [];
            this.salida.forEach(e => {
                nombres.push(`${e.mes_abre}-${e.anio}`);
                entradas.push(e.cantidad);
                grafica.push({
                    value: e.cantidad,
                    name: `${e.mes}-${e.anio}`
                })
            });
            this.graficaSalidaBarra.yAxis.data = nombres;
            this.graficaSalidaBarra.series[0].data = entradas;
            this.graficaSalida.series[0].data = grafica;
        },
        async getState(pais) {
            await this.Action_get_selects_states({ id_pais: pais })
        },
        async getEntradas(id) {
            await this.Action_get_entradas({ id_bodega: id })
            this.llenadoGraficaEntrada()
        },
        async getSalidas(id) {
            await this.Action_get_salidas({ id_bodega: id })
            this.llenadoGraficaSalida()
        },
        async handleFilter() {
            this.clearsFilters()
            this.listaFiltros()
        }
    }
}
</script>

<style lang="scss" scoped>
.card-grafica {
    background: #FFFFFF 0% 0% padding-box;
    box-shadow: 0px 3px 10px #00000029;
    border-radius: 15px;
}
</style>